/*
 * HERO FORGE CONFIDENTIAL
 * __________________
 *
 *  [2021] - Sky Castle Studios LLC
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Hero Forge LLC and its partners,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Hero Forge LLC
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Hero Forge LLC.
*/
const importTime = import('./Engine/Time.js');
const importApplication = import('./Application.js');

async function domLoadedAsync() {
    const elementsExist = () => {
        return document.getElementById('canvasMain') && document.getElementById('canvasMainSizer');
    };
    if (elementsExist()) {
        return Promise.resolve();
    }
    else {
        return new Promise((resolve, reject) => {
            let handlerId = null;
            const handler = () => {
                if (elementsExist()) {
                    clearInterval(handlerId);
                    resolve();
                }
            };
            handlerId = setInterval(handler, 100)
        });
    }
}

let app = null;

async function startAsync() {
    try {
        // Synchronise time. Todo: Roll this into Application startup.
        const { Time } = await importTime;
        await Time.initialiseServerTimeAsync();
        // Wait for page to load.
        await domLoadedAsync();
        // Start application.
        const { Application } = await importApplication;
        app = new Application();
        await app.startAsync();
    }
    catch (e) {
        console.error(e);
    }
}

startAsync();
